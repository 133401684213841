import React, { useState } from 'react';
import { Flex, Box, Card, Text, Heading, Image } from 'rebass';
import { useTranslation } from 'react-i18next';

import BoxitModal from '../../components/Modal';
import { Button } from '../../components/Button'
import BoxStatusInfo from '../../components/BoxStatusInfo';
import PrintBoxes from '../../components/PrintBoxes';
import Barcode from '../../components/Barcode';
import { Hide } from '../../components/hide';

import StatusReadyImg from '../../icons/status-ready.svg';
import StatusShippedImg from '../../icons/status-shipped.svg';
import StatusDeliveredImg from '../../icons/status-delivered.svg';
import StatusNotDeliveredImg from '../../icons/status-not_delivered.svg';
import StatusScannedImg from '../../icons/status-scanned.svg';
import CheckWhiteIcn from '../../icons/check-white.svg';

import { currency, datetime, boxSizeLabel } from '../../utils';

const StatusImage = ({ status, shippingCost }) => {
  const { t } = useTranslation();
  const mapStatusToImg = {
    paid: StatusReadyImg,
    scanned: StatusScannedImg,
    shipped: StatusShippedImg,
    dropped: StatusShippedImg,
    inTransit: StatusShippedImg,
    delivered: StatusDeliveredImg,
    not_delivered: StatusNotDeliveredImg,
  };
  const mapStatusToText = {
    pending: { text: 'Pending', color: '#e18d18' },
    scanned: { text: 'Pending', color: '#e18d18' },
    paid: { text: 'Ready', color: 'blues.twilight' },
    shipped: { text: 'Shipped', color: 'blues.twilight' },
    dropped: { text: 'Dropped', color: 'blues.twilight' },
    inTransit: { text: 'inTransit', color: 'blues.twilight' },
    delivered: { text: 'Delivered', color: 'dustyGreen' },
    not_delivered: { text: 'Not Delivered', color: 'pastelRed' },
  };

  if (!status) return <Text> ERROR!</Text>;

  return (
    <Box>
      <Flex flexDirection={['row', 'column', 'column']} alignItems="center">
        {status === 'pending' ? (
          <Text
            fontSize="25px"
            fontWeight="600"
            lineHeight="1.32"
            textAlign="center"
            color="#e18d18"
          >
            {shippingCost}
          </Text>
        ) : (
          <Image
            width={['32px', '32px', '32px']}
            mx="auto"
            py={['14px', '18px', '18px']}
            display="block"
            src={mapStatusToImg[status]}
          />
        )}

        <Text
          ml={['10px', 0, 0]}
          fontSize="14px"
          fontWeight="bold"
          lineHeight="1.14"
          letterSpacing="0.26px"
          textAlign="center"
          color={mapStatusToText[status].color}
        >
          {t(`my_boxit.item.status.${status}`)}
        </Text>
      </Flex>
    </Box>
  );
};

const StatusDate = props => (
  <Text
    {...props}
    pt="12px"
    fontSize="13px"
    lineHeight="1.85"
    letterSpacing="0.6px"
    textAlign="right"
    color="marine"
  />
);

const BoxIdText = ({ compact, ...props }) => (
  <Heading
    {...props}
    color="blues.peacock"
    fontSize={['17px', compact ? '14px' : '18px', '22px']}
    lineHeight="0.9"
    letterSpacing="1.06px"
    pb="24px"
  />
);

const AddressText = props => (
  <Text {...props} color="marine" fontSize="13px" lineHeight="1.38" />
);

const DeleteBoxButton = ({deleteFn, boxId, boxData}) => {
  //console.log('boxData',boxData)
  const { t } = useTranslation();
  return (
    <Box
      pb={['0','10px','10px']}
      pt={['0','10px','10px']}
      css={{
      textAlign: 'center',
      }}
      onClick={(e)=>{
        e.preventDefault();
          const recipient = {...boxData.recipient}
          delete recipient.__typename
          delete recipient.address.__typename
          //console.log(recipient)
          deleteFn({
            variables: {
              boxId:boxId,
              size:boxData.size,
              status:boxData.status,
              recipient:recipient
            },
          });
      }}>
      <Button pt="0px" css={{marginTop:"0px"}}>
        {t('my_boxit.item.remove')}
      </Button>
    </Box>
  )
}

const BoxItemLabel = props => (
  <Text
    {...props}
    fontSize="13px"
    fontWeight="bold"
    lineHeight="1.85"
    letterSpacing="0.6px"
    color="marine"
  />
);



export default function MyBoxitListItem({
  id,
  boxData,
  compact = false,
  withConfirm = false,
  isConfirmed = false,
  onBoxConfirmed,
  deleteFn,
  disablePrint
}) {
  const { t } = useTranslation();
  const { _id, _ts, size, status, recipient, shippingCost, createdAt } = boxData;
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [boxConfirmed, setBoxConfirmed] = useState(false);

  return (
    <>
      <BoxitModal
        title={`BOX ID: ${_id}`}
        width={['100%', '100%', '1120px']}
        minHeight={['100%', '100%', '520px']}
        subtitle={datetime(createdAt)}
        isOpen={showDetailsModal}
        onBackgroundClick={() => setShowDetailsModal(false)}
        onEscapeKeydown={() => setShowDetailsModal(false)}
        onCloseButtonClick={() => setShowDetailsModal(false)}
      >
        <BoxStatusInfo boxData={boxData} />
        {!disablePrint &&
          <Box pt="10px" textAlign={["center","right", "right"]}>
            <PrintBoxes pendingBoxes={[boxData]} />
          </Box>
        }
      </BoxitModal>
      <Flex>
        <Box
          width={1}
          pb="20px"
          alignSelf="center"
          css={{
            minHeight: '120px',
            maxWidth: '760px',
            ':hover': {
              cursor: 'pointer',
            },
          }}
        >
          <Flex
            width={1}
            flexDirection="row"
            justifyContent="space-between"
            bg="blues.veryLight"
            css={{
              borderRadius: '10px 10px 10px 10px',
            }}
          >
            <Card
              p={['6px', '14px', '14px']}
              borderRadius="10px 0 0 10px"
              bg="maize"
            >
              <Hide mobile>
                <BoxItemLabel>BOX ID:</BoxItemLabel>
                <BoxItemLabel>{t('my_boxit.item.ship_to')}:</BoxItemLabel>
              </Hide>
              <Heading
                pt="10px"
                fontSize="20px"
                fontWeight="900"
                textAlign="center"
                lineHeight="1.25"
                color="marine"
              >
                <Hide mobile>{boxSizeLabel(size.substr(3))}</Hide>
                <Hide tablet desktop>
                  {size.substr(3)}
                </Hide>
              </Heading>
              <Text
                mt="-8px"
                fontSize={['10px', '12px', '12px']}
                fontWeight="bold"
                letterSpacing="0.6px"
                textAlign="center"
                color="marine"
              >
                {t('my_boxit.item.size')}
              </Text>
            </Card>
            <Card 
              bg="blues.veryLight" 
              mx="auto" 
              borderRadius="0 10px 10px 0"
              onClick={() => setShowDetailsModal(true)}
              >
              <Flex pl="12px" flexDirection="row" flexWrap="wrap">
                <Box
                  py="12px"
                  pr={['0', '12px', '12px']}
                  width={compact ? '210px' : 'auto'}
                >
                  <BoxIdText compact={compact}>{id}</BoxIdText>
                  <AddressText>{recipient.fullname}</AddressText>
                  <AddressText>
                    {recipient.address.street}, {recipient.address.city}
                  </AddressText>
                  <AddressText>{recipient.address.zipCode} GREECE</AddressText>
                </Box>

                <Box py="12px" pr={['0', '12px', '12px']}>
                  <Hide mobile>
                    <Barcode value={id} width={compact ? '170px' : '220px'} />
                  </Hide>
                  <Hide tablet desktop>
                    <Barcode value={id} width="205px" />
                  </Hide>
                  <StatusDate>at {datetime(createdAt)}</StatusDate>
                </Box>
                <Hide tablet desktop css={{width:'100%', paddingBottom: '10px'}}>
                  <Flex pr="5px">
                    <StatusImage
                      status={status}
                      shippingCost={currency(shippingCost)}
                    />
                    <Box mx="auto" />
                    { status === 'pending' && 
                      <DeleteBoxButton
                        deleteFn={deleteFn}
                        boxId={_id}
                        boxData={boxData}
                      />
                    }
                  </Flex>
                </Hide>
              </Flex>
            </Card>

            <Card
              width={compact ? 'auto' : '118px'}
              borderRadius="0 10px 10px 0"
              py={['6px', '0px', '0px']}
              pr={['6px', '0px', '12px']}
              bg="blues.veryLight"
              alignSelf="center"
              display={['none', 'block', 'block']}
            >
              <StatusImage
                status={status}
                shippingCost={currency(shippingCost)}
              />
              { status === 'pending' && 
                <DeleteBoxButton
                  deleteFn={deleteFn}
                  boxId={_id}
                  boxData={boxData}
                />
              }
              </Card>
          </Flex>
        </Box>
        {withConfirm && (
          <Card
            width="189px"
            ml="7px"
            mb="20px"
            px="22px"
            pt="32px"
            bg={isConfirmed || boxConfirmed ? 'dustyGreen' : 'white'}
            borderRadius="10px"
            css={{ border: 'solid 1px #6db56a' }}
            onClick={() => {
              const val = !boxConfirmed;
              setBoxConfirmed(val);
              onBoxConfirmed(_id, val);
            }}
          >
            {(isConfirmed || boxConfirmed) ? (
              <Flex flexDirection="column" alignItems="center">
                <Image src={CheckWhiteIcn} height="28px" />
                <Heading
                  pt="8px"
                  fontSize="16px"
                  color={(isConfirmed || boxConfirmed) ? 'white' : 'dustyGreen'}
                  textAlign="center"
                  lineHeight="1"
                >
                  Το Δέμα Επιβεβαιώθηκε
                </Heading>
              </Flex>
            ) : (
              <Heading
                fontSize="16px"
                color="dustyGreen"
                textAlign="center"
                lineHeight="1.19"
              >
                ΠΑΤΗΣΤΕ ΓΙΑ ΕΠΙΒΕΒΑΙΩΣΗ ΔΕΜΑΤΟΣ
              </Heading>
            )}
          </Card>
        )}
      </Flex>
    </>
  );
}
