import React, { Fragment } from 'react';
import { Box, Flex, Heading, Text } from 'rebass';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { currency } from '../../utils';

import BoxCheckoutItem from './BoxCheckoutItem';

/**
 *
 * @param {*} param0
 * @returns
 */
export default function BoxesCheckoutPanel({
  pendingBoxes,
  totalCost,
  removeBox,
}) {
  const { t } = useTranslation();
  const { register } = useFormContext();

  return (
    <Fragment>
      <Flex
        alignItems="baseline"
        flexWrap="wrap"
        css={{
          borderBottom: '1px solid #cfd4dc',
        }}
      >
        <Box>
          <Text fontSize="25px" color="blues.peacock">
            {t('my_boxit.boxCheckoutModal.checkoutPanelText1')}
          </Text>
        </Box>
        <Box mx="auto" />
        <Box>
          <Text fontSize="17px" color="blues.peacock" pr="8px" mr="8px">
            {t('my_boxit.boxCheckoutModal.checkoutPanelText2')}
          </Text>
          <Heading fontSize="57px" color="blues.peacock">
            € {currency(totalCost)}
          </Heading>
        </Box>
      </Flex>
      <Flex
        flexWrap="wrap"
        css={{
          borderBottom: '1px solid #cfd4dc',
        }}
      >
        <Box
          width={[1]}
          mt="12px"
          pt="12px"
          pr="2px"
          css={{
            height: '346px',
            overflowY: 'scroll',
          }}
        >
          {pendingBoxes.map((box, i) => (
            <BoxCheckoutItem removeBox={removeBox} key={i} box={box} />
          ))}
        </Box>
      </Flex>
    </Fragment>
  );
}
