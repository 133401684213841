import React from 'react';
import { Box, Image, Text } from 'rebass';
import ScaleLoader from "react-spinners/ScaleLoader";
import ScannerIcn from '../../icons/scanner.svg';
import Alert from '../../../components/Alert';

const ScanningPanel = ({searchBox, alertMessage, closeModal, loadingIndicator}) => (
    <>
      <Image src={ScannerIcn} />
      <Text
        pt="5px"
        fontSize="25px"
        lineHeight="1.04"
        letterSpacing="0.2px"
        color="blues.medium"
      >
        Σκανάρετε Τώρα
      </Text>
      <Text
        pt="5px"
        px="20px"
        fontSize="15px"
        lineHeight="1.17"
        letterSpacing="0.7px"
        color="marine"
        textAlign="center"
      >
        Χρησιμοποιείστε το Scanner για να σκανάρετε το Barcode που έχετε λάβει ή πληκτρολογήστε το Box ID
      </Text>
      <Box mt={'10px'}>
      {!loadingIndicator &&
        <input autoFocus type="number" minLength="18" maxLength="18" pattern="[0-9.]+" onChange={(e)=>{
          if(e.target.value.length == 18 ) {
            searchBox(e.target.value);
            e.target.value = ""
          }
        }}></input>
      }
      </Box>
      <Box mt={'10px'}>
        <ScaleLoader
              css={{}}
              size={40}
              color={"#01549b"}
              loading={loadingIndicator}
            />
        <Alert message={alertMessage} />
      </Box>
    </>
  );

  export default ScanningPanel