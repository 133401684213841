import React from 'react';
import ContentLoader from 'react-content-loader';
import { Flex, Box, Card, Text, Heading } from 'rebass';

import { Hide } from '../../components/hide';

const BoxItemLabel = props => (
  <Text
    {...props}
    fontSize="13px"
    fontWeight="bold"
    lineHeight="1.85"
    letterSpacing="0.6px"
    color="marine"
  />
);

const MyBoxitListItemLoader = () => (
  <Box
    width={1}
    pb="20px"
    alignSelf="center"
    css={{
      minHeight: '120px',
      maxWidth: '760px',
    }}
  >
    <Flex
      width={1}
      flexDirection="row"
      justifyContent="space-between"
      bg="blues.veryLight"
      css={{
        borderRadius: '10px 10px 10px 10px',
      }}
    >
      <Card p={['6px', '14px', '14px']} borderRadius="10px 0 0 10px" bg="maize">
        <Hide mobile>
          <BoxItemLabel>BOX ID:</BoxItemLabel>
          <BoxItemLabel>Ship To:</BoxItemLabel>
        </Hide>
        <Heading
          pt="10px"
          fontSize="20px"
          fontWeight="900"
          textAlign="center"
          lineHeight="1.25"
          color="marine"
        >
          No.
        </Heading>
        <Text
          mt="-8px"
          fontSize={['10px', '12px', '12px']}
          fontWeight="bold"
          letterSpacing="0.6px"
          textAlign="center"
          color="marine"
        >
          size
        </Text>
      </Card>
      <Card bg="blues.veryLight" mx="auto">
        <Flex pl="12px" flexDirection="row" flexWrap="wrap">
          <Box width={290} py="12px" pr={['0', '12px', '12px']}>
            <ContentLoader
              height={120}
              width={277}
              speed={2}
              primaryColor="#d7d8da"
              secondaryColor="#ecedf0"
            >
              <rect x="0" y="0" rx="3" ry="3" width="277" height="14" />
              <rect x="0" y="50" rx="3" ry="3" width="200" height="10" />
              <rect x="0" y="70" rx="3" ry="3" width="180" height="10" />
              <rect x="0" y="90" rx="3" ry="3" width="120" height="10" />
            </ContentLoader>
          </Box>

          <Box width={256} py="12px" pr={['0', '12px', '12px']}>
            <ContentLoader
              height={120}
              width={244}
              speed={2}
              primaryColor="#d7d8da"
              secondaryColor="#ecedf0"
            >
              <rect x="0" y="0" rx="3" ry="3" width="240" height="60" />
              <rect x="60" y="80" rx="3" ry="3" width="180" height="8" />
            </ContentLoader>
          </Box>
        </Flex>
      </Card>
      <Card
        width={80}
        borderRadius="0 10px 10px 0"
        p={['6px', '18px', '18px']}
        bg="blues.veryLight"
      >
        <Hide mobile>
          <Flex alignItems="center">
            <Box width={1}>
              <ContentLoader
                height={120}
                width={60}
                speed={2}
                primaryColor="#d7d8da"
                secondaryColor="#ecedf0"
              >
                <rect x="10" y="20" rx="3" ry="3" width="40" height="40" />
                <rect x="0" y="80" rx="3" ry="3" width="60" height="12" />
              </ContentLoader>
            </Box>
          </Flex>
        </Hide>
      </Card>
    </Flex>
  </Box>
);

export default MyBoxitListItemLoader;
