import React, { useState }  from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useIdentityContext } from 'react-netlify-identity';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text, Heading, } from 'rebass';
import { gql } from 'apollo-boost';
import { Hide } from '../hide';

import BoxesCheckoutModal from '../BoxesCheckoutModal';

import { currency } from '../../utils';


export const MY_PENDING_BOXES = gql`
query FindPendingAccountBoxes($accountId: ID!) 
{
  findBoxByAccountNStatusNotDeletedRev(accountId: $accountId, status: pending)
  {
    data {
        _id
        _ts
        size
        status
        createdAt
        recipient {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
        deliveryPoint {
          name
          contact {
            fullname
            address {
              street
              zipCode
              city
            }
            phone
          }
        }
        pickupPoint {
          name
          contact {
            fullname
            address {
              street
              zipCode
              city
            }
            phone
          }
        }
        history {
          status
          _ts
          _id
        }
        deliveryAtHome
        shippingCost
      }
      before
    	after
  }
}`


const CheckoutInfoHeader = () => {
    const { t } = useTranslation();
    const identity = useIdentityContext();

    const queryVars = { 
        accountId: identity.user.user_metadata.aid, 
       }
    
    const queryRes = useQuery(MY_PENDING_BOXES, {
        variables: queryVars
    });
    
    const { loading, error, data, refetch } = queryRes

    if(loading){
        return null
    }

    if(error){
        console.log(error)
        return null;
    }

    const pendingBoxes = data.findBoxByAccountNStatusNotDeletedRev.data;

    if(pendingBoxes.length === 0){
        return null
    }

  
    const checkoutItems = pendingBoxes.reduce(
        (acc, box) => {
            return {
                totalShippingCost: acc.totalShippingCost + box.shippingCost,
                boxes: acc.boxes.concat([box]),
            };
        },
        { totalShippingCost: 0, boxes: [] }
    );
  
    return (
        <Flex
            flexDirection="column"
            pt="20px"
            mt="20px"
            css={{
            borderTop: `1px solid ${props => props.theme.colors.lightBlueGrey}`,
            }}
        >
 
      <Box p="14px" css={{ border: '1px solid #6db56a' }}>
        <Flex flexDirection="row" flexWrap="wrap" alignItems="center">
          <Box width={[1, 5 / 9, 5 / 9]}>
            <Heading fontSize="15px" color="dustyGreen">
              {t('my_boxit.sending.you_have_created', {
                count: checkoutItems.boxes.length,
              })}
            </Heading>
            <Text
              fontSize="15px"
              color="dustyGreen"
              lineHeight="1.1"
              letterSpacing="0.94px"
            >
              {t('my_boxit.sending.please_checkout_and_pay')}
            </Text>
          </Box>
          <Box mx="auto" />
          <Box>
            <Hide desktop tablet>
              <Box mt="15px" />
            </Hide>
            <Flex flexDirection="row" flexWrap="wrap" alignItems="center">
              <Heading
                fontSize="34px"
                fontWeight={900}
                lineHeight="0.74"
                color="blues.peacock"
              >
                {currency(checkoutItems.totalShippingCost)}
              </Heading>
              <BoxesCheckoutModal refetch={refetch} checkoutItems={checkoutItems} />
            </Flex>
          </Box>
        </Flex>
      </Box>
      </Flex>
    );
  };

  export default CheckoutInfoHeader;