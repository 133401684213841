import React, { useState } from 'react';
import { Box, Button, Card, Flex, Image, Text, Heading } from 'rebass';
import { gql } from 'apollo-boost';
import { useLazyQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import ScaleLoader from "react-spinners/ScaleLoader";

import BoxitModal from '../Modal';
import { Container } from '../../../components/container';
import { MyBoxitListItem } from '../../../components/MyBoxitList';

import BackgroundImage from '../../icons/shape-box.svg';

import BarcodeScannerImage from '../../images/barcode_scanner@2x.jpg';

import ScannerIcn from '../../icons/scanner.svg';
import CheckIcn from '../../icons/check.svg';

import Alert from '../../../components/Alert';

import ZebraPrint from '../ZebraPrint';

const MyBoxitList = props => (
  <Box p="0px 100px" css={{ height: '470px', width:'100%', overflowY: 'scroll' }}>
    <Flex {...props} flexDirection="column" />
  </Box>
);

const GET_BOX_DETAILS = gql`
  query GetBoxDetails($boxId: ID!) {
    findBoxByID(id: $boxId) {
      _id
      _ts
      size
      status
      shippingCost
      history {
        _id
        _ts
        status
      }
      recipient {
        fullname
        address {
          street
          zipCode
          city
        }
        email
        phone
      }
      account{
        contact{
          fullname
        }
      }
      deliveryAtHome
      deliveryPoint {
        _id
        name
        lat
        lng
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
      }
      pickupPoint {
        _id
        name
        lat
        lng
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
      }
    }
  }
`;

const ScanningPanel = ({searchBox, alertMessage, closeModal, loadingIndicator}) => (
  <>
    <Image src={ScannerIcn} />
    <Text
      pt="5px"
      fontSize="25px"
      lineHeight="1.04"
      letterSpacing="0.2px"
      color="blues.medium"
    >
      Σκανάρετε Τώρα
    </Text>
    <Text
      pt="5px"
      px="20px"
      fontSize="15px"
      lineHeight="1.17"
      letterSpacing="0.7px"
      color="marine"
      textAlign="center"
    >
      Χρησιμοποιείστε το Scanner για να σκανάρετε το Barcode που έχετε λάβει ή πληκτρολογήστε το Box ID
    </Text>
    <Box mt={'10px'}>
    {!loadingIndicator &&
      <input autoFocus type="number" minLength="18" maxLength="18" pattern="[0-9.]+" onChange={(e)=>{
        if(e.target.value.length == 18 ) {
          searchBox(e.target.value);
          e.target.value = ""
        }
      }}></input>
    }
    </Box>
    <Box mt={'10px'}>
      <ScaleLoader
            css={{}}
            size={40}
            color={"#01549b"}
            loading={loadingIndicator}
          />
      <Alert message={alertMessage} />
    </Box>
  </>
);


const ConfirmedPanel = () => (
  <>
    <Image pt="12px" src={CheckIcn} />
    <Text
      pt="20px"
      fontSize="25px"
      lineHeight="1.04"
      letterSpacing="0.2px"
      color="dustyGreen"
    >
      Μόλις προσθέσατε ένα Δέμα!
    </Text>
  </>
);

export default function BoxPanel({
  addButtonText,
  helpText,
  scannedBoxes,
  isSendBoxPage,
  totalBoxes,
  handleBoxScanning
}) {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [boxConfirmed, setBoxConfirmed] = useState(false);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const closeModal = () =>{
    setModalOpen(false);
  }

  const [getBoxDetails, { loading, data }] = useLazyQuery(GET_BOX_DETAILS, {
    onCompleted: data => {
      setLoadingIndicator(false);
      if (data.findBoxByID) {
        const { err } = handleBoxScanning(data.findBoxByID);
        if(err){
          setAlertMessage(err);
        }else{
          setBoxConfirmed(true)
          setTimeout(closeModal, 100);
        }
      } else {
        setAlertMessage('Λανθασμένος κωδικός');
      }
    },
    onError: (err)=>{
      console.log('error',err)
      setLoadingIndicator(false);
      setAlertMessage('Κάτι πήγε λάθος');
    },
    fetchPolicy: 'network-only',
  });


  const searchBox = (boxId) =>{
    setAlertMessage(null);
    setLoadingIndicator(true);
    getBoxDetails({ variables: { boxId } });
  }

  const handleClick = () => {
    setLoadingIndicator(false)
    setAlertMessage(null);
    setBoxConfirmed(false);
    setModalOpen(true);
  };

  return (
    <Container>
      <Flex>
     
        <Box
          mr="16px"
          bg="white"
          width="100%"
          css={{
            borderRadius: 16,
            minHeight: '500px',
          }}
        >
           <Box>
            <Flex
              flexDirection="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Heading
                fontSize="19px"
                lineHeight="1.37"
                letterSpacing="0.16px"
                color="dustyGreen"
              >
                ΕΠΙΒΕΒΑΙΩΣΗ ΔΕΜΑΤΩΝ ΑΠΟ ΔΙΑΧΕΙΡΙΣΤΗ ΤΟΥ ΣΤΑΘΜΟΥ
              </Heading>
              <Heading
                fontSize="48px"
                lineHeight="1.4"
                letterSpacing="-3.52px"
                color="dustyGreen"
              >
                {scannedBoxes.length}/{totalBoxes.length}
              </Heading>
            </Flex>
          </Box>
          <Flex flexWrap="wrap">
            {scannedBoxes && scannedBoxes.length > 0 ? (
              <MyBoxitList>
                {scannedBoxes.map((box, idx) => (
                  <MyBoxitListItem
                    key={`${box._id}_${idx}`}
                    id={box._id}
                    boxData={box}
                    compact
                    disablePrint
                  />
                ))}
              </MyBoxitList>
            ) : (
              <Card
                width={1}
                backgroundImage={'url(' + BackgroundImage + ')'}
                backgroundSize="auto"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                css={{
                  height: '400px',
                }}
              />
            )}
          </Flex>
        </Box>
    
      </Flex>
    </Container>
  );
}
