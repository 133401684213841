import { gql } from 'apollo-boost';

export const MY_BOXES = gql`
  query FindAccountBoxes($accountId: ID!, $cursorId: String) {
    boxesByAccountIdNotDeletedAsc(
      accountId: $accountId
      _size: 5
      _cursor: $cursorId
    ) {
      data {
        _id
        _ts
        size
        status
        createdAt
        recipient {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
        deliveryPoint {
          name
          contact {
            fullname
            address {
              street
              zipCode
              city
            }
            phone
          }
        }
        pickupPoint {
          name
          contact {
            fullname
            address {
              street
              zipCode
              city
            }
            phone
          }
        }
        deliveryAtHome
        shippingCost
        history {
          status
          _ts
          _id
        }
      }
      before
      after
    }
  }
`;

export const CREATE_PURCHASE_TICKET = gql`
  mutation CreatePurchaseTicket(
    $accountId: ID!
    $totalCost: Float!
    $boxIds: [ID!]!
  ) {
    createPurchaseTicket(
      data: { accountId: $accountId, totalCost: $totalCost, boxIds: $boxIds }
    ) {
      purchaseId
      AcquirerId
      MerchantId
      PosId
      User
      LanguageCode
      MerchantReference
      ParamBackLink
    }
  }
`;

export const UPDATE_PURCHASED_BOXES = gql`
  mutation UpdateBoxesPurchase($purchaseId: ID!, $boxStatus: String!) {
    updatePurchaseBoxesStatus(purchaseId: $purchaseId, boxStatus: $boxStatus) {
      _id
    }
  }
`;

export const FIND_PURCHASE_BY_MERCHANT_REF = gql`
  query FindPurchaseByMerchant($merchantRef: String!) {
    findPurchaseByMerchantRef(merchantRef: $merchantRef) {
      data {
        _id
        totalCost
        status
        merchantRef
        account {
          contact {
            email
          }
        }
        boxes {
          _id
          size
          recipient {
            fullname
            address {
              street
              zipCode
              city
            }
          }
        }
        transTicket {
          TranTicket
        }
      }
    }
  }
`;

export const UPDATE_PURCHASE_MUTATION = gql`
  mutation UpdatePurchase(
    $id: ID!
    $status: PaymentStatus!
    $totalCost: Float!
    $merchantRef: String!
    $transResponse: TransPostResponseInput
  ) {
    updatePurchase(
      id: $id
      data: {
        totalCost: $totalCost
        merchantRef: $merchantRef
        status: $status
        transResponse: $transResponse
      }
    ) {
      _id
      status
      boxes {
        _id
      }
    }
  }
`;

export const SEND_EMAIL_BOXES_INFO = gql`
  mutation SendEmail($emailData: EmailTemplateInput!) {
    sendEmailTemplate(data: $emailData)
  }
`;
