import React from 'react';
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import { Flex } from 'rebass';

import Modal, { ModalProvider, BaseModalBackground } from 'styled-react-modal';

const SpecialModalBackground = styled(BaseModalBackground)`
  backdrop-filter: blur(11px);
  background-color: rgba(3, 46, 79, 0.39);
`;

const StyledModal = Modal.styled`
  ${space}
  ${layout}  
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.76) 2%, #ffffff 70%);
  border-radius: 16px;
`;

export default function BoxitModal({ children, ...props }) {
  return (
    <StyledModal {...props}>
      <Flex py="28px" width="530px" flexDirection="column" alignItems="center">
        {children}
      </Flex>
    </StyledModal>
  );
}

export const BoxitModalProvider = ({ children }) => {
  return (
    <ModalProvider backgroundComponent={SpecialModalBackground}>
      {children}
    </ModalProvider>
  );
};
