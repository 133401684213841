import React, { useState, useRef, Fragment } from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass';

import { useMutation } from '@apollo/react-hooks';
import { useIdentityContext } from 'react-netlify-identity';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';

import { useGlobalContext } from '../../GlobalState';

import BoxesCheckoutPanel from './BoxesCheckoutPanel';
import BoxitModal, { ModalFooterButton } from '../../components/Modal';

import { MY_BOXES, CREATE_PURCHASE_TICKET } from './graphql';

/**
 * Hidden Form Component that contains all the data
 * posted to bank redirection url.
 */
const BankHiddenForm = ({ data, completed }) => {
  const bankForm = useRef(null);

  if (!data) {
    return null;
  }

  if (completed && data) {
    bankForm.current.submit();
  }

  const BANK_POST_URL = 'https://paycenter.piraeusbank.gr/redirection/pay.aspx';

  return (
    <form action={BANK_POST_URL} method="POST" ref={bankForm}>
      <input name="AcquirerId" type="hidden" value={data.AcquirerId} />
      <input name="MerchantId" type="hidden" value={data.MerchantId} />
      <input name="PosId" type="hidden" value={data.PosId} />
      <input name="User" type="hidden" value={data.User} />
      <input name="LanguageCode" type="hidden" value={data.LanguageCode} />
      <input
        name="MerchantReference"
        type="hidden"
        value={data.MerchantReference}
      />
      <input name="ParamBackLink" type="hidden" value={data.ParamBackLink} />
    </form>
  );
};

/**
 * Modal component that is responsible for checkout process.
 */
const CheckoutModal = ({
  cleanup,
  checkoutModalOpen,
  pendingBoxes,
  totalCost,
  removeBox,
  completed,
  setCompleted,
}) => {
  const identity = useIdentityContext();
  const { t } = useTranslation();

  const [hiddenFormValues, setHiddenFormValues] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [purchasing, setPurchasing] = useState(false);

  const [purchaseBoxes] = useMutation(CREATE_PURCHASE_TICKET, {
    onCompleted: data => {
      console.log('CREATE PURCHASE COMPLETED: ' + JSON.stringify(data));
      if (data.createPurchaseTicket.PosId) {
        setHiddenFormValues(data.createPurchaseTicket);
        setCompleted(true);

        /*
        AcquirerId: null
        LanguageCode: null
        MerchantId: null
        MerchantReference: null
        ParamBackLink: null
        PosId: null
        User: null
        */
      } else {
        setErrorMsg('error creating Purchase');
      }
    },
    onError: e => {
      console.log('CREATE PURCHASE FAILED: ' + e);
      setErrorMsg('error creating Purchase');
    },
  });

  const checkout = () => {
    setPurchasing(true);
    console.log(pendingBoxes);

    purchaseBoxes({
      variables: {
        accountId: identity.user.user_metadata.aid,
        totalCost,
        boxIds: pendingBoxes.map(box => box._id),
      },
    });
  };

  const handleClose = () => {
    cleanup();
    setAgreeWithTerms(false);
    setPurchasing(false);
    setErrorMsg(null);
  };

  return (
    <BoxitModal
      title={t('my_boxit.boxCheckoutModal.title')}
      width={['100%', '100%', '1120px']}
      minHeight={['100%', '100%', '520px']}
      isOpen={checkoutModalOpen}
      onCloseButtonClick={() => handleClose()}
      onEscapeKeydown={() => handleClose()}
      onSubmitButtonClick={checkout}
      footer={() => {
        return !completed && totalCost !== 0 && !errorMsg ? (
          <Box>
            <Flex mx="50px" alignItems="center" flexWrap="wrap">
              <Box width={[1, 1, 1 / 2]}>
                <label>
                  <input
                    onChange={e => {
                      setAgreeWithTerms(!agreeWithTerms);
                    }}
                    type="checkbox"
                    defaultChecked={agreeWithTerms}
                  ></input>
                  <Text pl="10px" as="span" fontSize="12px" color="red">
                    {t('my_boxit.boxCheckoutModal.terms')}
                  </Text>
                </label>
              </Box>
              <Box
                textAlign={['center', 'right', 'right']}
                pb={'30px'}
                width={[1, 1, 1 / 2]}
              >
                <ModalFooterButton
                  disabled={!agreeWithTerms || purchasing}
                  large
                  type="submit"
                >
                  {t('my_boxit.boxCheckoutModal.checkout')}
                </ModalFooterButton>
              </Box>
            </Flex>
          </Box>
        ) : null;
      }}
    >
      {!completed && !errorMsg && (
        <BoxesCheckoutPanel
          pendingBoxes={pendingBoxes}
          totalCost={totalCost}
          removeBox={removeBox}
        />
      )}

      <BankHiddenForm data={hiddenFormValues} completed={completed} />
      {purchasing && (
        <Box>
          <Text>{t('purchase.redirecting')}</Text>
        </Box>
      )}
      {errorMsg && (
        <Box>
          <Text>
            {t('purchase.bank_error.text1') + ' !!!!!!!!!! ' + errorMsg}
          </Text>
          <Text>{t('purchase.bank_error.text2')}</Text>
        </Box>
      )}
    </BoxitModal>
  );
};

const BoxesCheckoutModal = ({ checkoutItems, refetch }) => {
  const { t } = useTranslation();
  const { user } = useGlobalContext();
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);
  const [pendingBoxes, setPendingBoxes] = useState(checkoutItems.boxes);
  const [totalCost, setTotalCost] = useState(checkoutItems.totalShippingCost);
  const [completed, setCompleted] = useState(false);

  const identity = useIdentityContext();

  const [getBoxes] = useLazyQuery(MY_BOXES, {
    onCompleted: data => {},
    onError: err => {
      console.log('error', err);
    },
    fetchPolicy: 'no-cache',
  });

  const queryVars = {
    accountId: identity.user.user_metadata.aid,
    cursorId: null,
  };

  const removeBox = boxId => {
    const filteredBoxes = pendingBoxes.filter(box => box._id !== boxId);
    setPendingBoxes(filteredBoxes);
    const recalculatedCost = filteredBoxes.reduce(
      (acc, box) => acc + box.shippingCost,
      0
    );
    setTotalCost(recalculatedCost);
  };

  const cleanup = () => {
    if (completed) {
      getBoxes({
        variables: queryVars,
      });
      refetch();
    }
    setCheckoutModalOpen(false);
  };

  const openModal = () => {
    setCheckoutModalOpen(true);
    setPendingBoxes(checkoutItems.boxes);
    setTotalCost(checkoutItems.totalShippingCost);
    setCompleted(false);
  };

  return (
    <Fragment>
      {user.isEnabled && (
        <Button
          ml="10px"
          borderRadius="4px"
          bg="blues.peacock"
          py="16px"
          css={{
            ':hover': {
              cursor: 'pointer',
            },
          }}
          onClick={() => openModal()}
        >
          <Heading
            fontSize="12px"
            fontWeight={900}
            lineHeight={1.75}
            letterSpacing="2px"
          >
            {t('my_boxit.sending.checkout_now')}
          </Heading>
        </Button>
      )}
      <CheckoutModal
        removeBox={removeBox}
        checkoutModalOpen={checkoutModalOpen}
        cleanup={cleanup}
        totalCost={totalCost}
        pendingBoxes={pendingBoxes}
        completed={completed}
        setCompleted={setCompleted}
      />
    </Fragment>
  );
};

export default BoxesCheckoutModal;
