
import React from 'react';
import { Link } from 'gatsby';
import { Location } from "@reach/router"
import queryString from 'query-string';

import FooterButton from './FooterButton';
  
const HomeNavBtn = ({text}) => (
    <Location >
        {({ location }) => {
        const query = location.search ? queryString.parse(location.search): {}
        return( 
        <Link to={`/courier/?stationId=${query.stationId}`}>
            <FooterButton>{text}</FooterButton>
        </Link>
        )}
        }
    </Location>
)

export default HomeNavBtn;
