import React from 'react';
import { Box, Flex, Heading, Text, Image } from 'rebass';

import { Container } from '../../../components/container';
import BoxIcn from '../../icons/box.svg';

export default function BoxCompletedPanel({ infoText }) {
  return (
    <Container>
      <Flex pt="86px" px="40px" flexDirection="column" alignItems="center">
        <Image src={BoxIcn} height="110px" />
        <Heading fontSize="48px" color="white">
          Επιτυχής Επιβεβαίωση!
        </Heading>
        <Text fontSize="29px" textAlign="center" color="maize">
          {infoText}
        </Text>
        <Heading fontSize="68px" color="white">
          Σας Ευχαριστούμε!
        </Heading>
      </Flex>
    </Container>
  );
}
