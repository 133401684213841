import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, Heading, Text } from 'rebass';

import { Container } from '../../../components/container';
import Alert from '../../../components/Alert';

const Input = styled.input`
  font-size: 130px;
  padding-left: 28px;
  letter-spacing: 50px;
  width: 540px;
  height: 118px;
  border-radius: 5px;
  border: solid 1px #cfd4dc;
  -webkit-text-security: disc;
  -moz-webkit-text-security: disc;
  -moz-text-security: disc;
`;

const PinInput = ({digits, onFilled, disablePinInput})=>{
  const [val, setVal] = useState("")
  
  const onChange =(e)=>{
    if(e.target.value.length !== digits+1)
    { 
      setVal(e.target.value)
      if(e.target.value.length === digits){
        onFilled(e.target.value)
        setVal("");
      }
    }
  }

  return (
    <Input type="number"  
      disabled={disablePinInput}
      value={val} 
      pattern="/^-?\d+\.?\d*$/"
      max={99999} 
      onChange={onChange}
    />
  )
}

export default function CodeConfirmPanel({
  title,
  description1,
  description2,
  onSetPin,
  disablePinInput
}) {
  return (
    <Container>
      <Flex pt="22px" flexDirection="column" alignItems="center">
        <Heading
          fontSize="48px"
          lineHeight="1.4"
          letterSpacing="-3.52px"
          color="white"
        >
          {title}
        </Heading>
        <Text
          pt="25px"
          px="70px"
          fontSize="20px"
          lineHeight="1.4"
          color="white"
          textAlign="center"
        >
          {description1}
        </Text>
        <Text fontSize="20px" lineHeight="1.4" color="white" textAlign="center">
          {description2}
        </Text>
        <Box pt="42px">
          <PinInput disablePinInput={disablePinInput} digits={5} onFilled={onSetPin}/>
        </Box>
      </Flex>
    </Container>
  );
}
