import React, { useState }  from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useIdentityContext } from 'react-netlify-identity';
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text, Heading, } from 'rebass';
import { gql } from 'apollo-boost';
import { Button } from '../../components/Button'
import Alert from '../../components/Alert';
import MyBoxitListItem from './MyBoxitListItem';
import MyBoxitListItemLoader from './MyBoxitListItemLoader';
import ScaleLoader from "react-spinners/ScaleLoader";

import { MY_PENDING_BOXES } from '../CheckoutInfoHeader/CheckoutInfoHeader';

const Wrapper = ({ children, ...props }) => (
  <Flex
    {...props}
    flexDirection="column"
    pt="20px"
    mt="20px"
    css={{
      borderTop: `1px solid ${props => props.theme.colors.lightBlueGrey}`,
    }}
  >
    {children}
  </Flex>
);


export const DELETE_BOX = gql`
  mutation DeleteBox(
    $boxId: ID!,
    $recipient: ContactInput!,
    $status:BoxStatus!,
    $size:BoxSize!
    ) {
  updateBox(id:$boxId, data: {
      size:$size
      recipient:$recipient
      status:$status
      isDeleted: true
    }){
      _id
    }
  }`;

const MyBoxitList = ({ query, ...props }) => {
  const identity = useIdentityContext();
  const { t } = useTranslation();
  const [loadingIndicator, setLoadingIndicator] = useState(false);

  const queryVars = { 
    accountId: identity.user.user_metadata.aid, 
    cursorId: null
   }

  const { loading, error, data, fetchMore } = useQuery(query, {
    variables: queryVars
  });

  const [deleteBox] = useMutation(DELETE_BOX,{
    update(cache, { data: {updateBox}}){

      // if(refetchPending){refetchPending();}

      const { boxesByAccountIdNotDeletedAsc } = cache.readQuery({
        query: query,
        variables: queryVars
      });
      const filtered = boxesByAccountIdNotDeletedAsc.data.filter(box=> box._id !== updateBox._id)
      cache.writeQuery({
        query: query,
        variables: queryVars,
        data: {
          boxesByAccountIdNotDeletedAsc: {
            ...boxesByAccountIdNotDeletedAsc,
              data: filtered,
              before: boxesByAccountIdNotDeletedAsc.before,
              after: boxesByAccountIdNotDeletedAsc.after,
              __typename: boxesByAccountIdNotDeletedAsc.__typename,
          },
        },
      });

      const { findBoxByAccountNStatusNotDeletedRev } = cache.readQuery({
        query: MY_PENDING_BOXES,
        variables: { 
          accountId: identity.user.user_metadata.aid
         }
      });

      const filteredPending = findBoxByAccountNStatusNotDeletedRev.data.filter(box=> box._id !== updateBox._id)

       cache.writeQuery({
        query: MY_PENDING_BOXES,
        variables: { 
          accountId: identity.user.user_metadata.aid,
         },
        data: {
          findBoxByAccountNStatusNotDeletedRev: {
            ...findBoxByAccountNStatusNotDeletedRev,
            data: filteredPending,
            before: findBoxByAccountNStatusNotDeletedRev.before,
            after: findBoxByAccountNStatusNotDeletedRev.after,
            __typename: findBoxByAccountNStatusNotDeletedRev.__typename,

          },
        },
      });

    },
    onError: error => {
      console.error(`Box creation failed! ${JSON.stringify(error)}`);
    },
    onCompleted: async data => {
      console.log('completed removing')
    },
  });

  // When still loading...
  if (loading) {
    return (
      <Wrapper>
        <MyBoxitListItemLoader />
        <MyBoxitListItemLoader />
        <MyBoxitListItemLoader />
      </Wrapper>
    );
  }

  // When there's an error!
  if (error) {
    setLoadingIndicator(false); 
    return (
      <Wrapper>
        {error.graphQLErrors.map(({ message }, i) => (
          <Alert key={i} message={message}></Alert>
        ))}
        {error.networkError && (
          <Alert message={t('validation.failed_to_retrieve_data')} />
        )}
      </Wrapper>
    );
  }

  const boxesData = data.boxesByAccountIdNotDeletedAsc.data;
  // When there are no boxes.
  if (!boxesData || boxesData.length === 0) {
    return (
      <Wrapper>
        <Box width={1} bg="blues.veryLight" alignItems="center" py="22px">
          <Heading fontSize="16px" color="blues.twilight" textAlign="center">
            {t('my_boxit.sending.no_new_boxes')}
          </Heading>
        </Box>
      </Wrapper>
    );
  }

  return (
    <>
    <Wrapper>
      {boxesData.map(data => (
        <MyBoxitListItem deleteFn={deleteBox} key={data._id} id={data._id} boxData={data} />
      ))}
    </Wrapper>
    {boxesData.after !== null &&
    <>
    <Box textAlign="center">     
      <ScaleLoader
          css={{}}
          size={40}
          color={"#01549b"}
          loading={loadingIndicator}
        />
    </Box>

    <Button 
      fontSize="12px"
      width="166px"
      fontSize="12px"
      lineHeight="normal"
      css={{ margin: 'auto', display: 'block' }}
      disabled={!data.boxesByAccountIdNotDeletedAsc.after}
      onClick={()=>{
        setLoadingIndicator(true); 
        fetchMore({
        variables: {
          accountId: identity.user.user_metadata.aid, 
          cursorId: data.boxesByAccountIdNotDeletedAsc.after
        },
        updateQuery: (prev, { fetchMoreResult, ...rest }) => {
          if (!fetchMoreResult) return prev;
          const addedBoxes = [
            ...prev.boxesByAccountIdNotDeletedAsc.data,
            ...fetchMoreResult.boxesByAccountIdNotDeletedAsc.data,
            ]
          const result = [];
          const map = new Map();
          for (const item of addedBoxes) {
              if(!map.has(item._id)){
                  map.set(item._id, true);    // set any value to Map
                  result.push(item);
              }
          }
          setLoadingIndicator(false); 
          return {
            ...fetchMoreResult,
            boxesByAccountIdNotDeletedAsc: {
              ...fetchMoreResult.boxesByAccountIdNotDeletedAsc,
              data: result,
              before: fetchMoreResult.boxesByAccountIdNotDeletedAsc.before,
              after: fetchMoreResult.boxesByAccountIdNotDeletedAsc.after,
              __typename: fetchMoreResult.boxesByAccountIdNotDeletedAsc.__typename,
              }
            }
      }
    })}
    }
    >
      {t('my_boxit.sending.loadMore')}
    </Button>
    </>
  }
    </>
  );
};

export default MyBoxitList;
