import React from 'react';
import { Box, Card, Flex, Heading, Text } from 'rebass';

import { useTranslation } from 'react-i18next';

import { currency, datetime } from '../../utils';

import styled from 'styled-components';

/**
 * Represents a single item in checkout list.
 *
 * @param {*} param0
 * @returns
 */
export default function BoxCheckoutItem({
  box: {
    _id,
    _ts,
    createdAt,
    shippingCost,
    recipient: { fullname, address },
  },
  removeBox,
}) {
  const { t } = useTranslation();
  return (
    <Card bg="blues.veryLight" borderRadius="10px" mb="18px">
      <Flex>
        <Card pt="12px" bg="maize" borderRadius="10px 0 0 10px">
          <Heading
            mx="18px"
            fontSize={['13px']}
            lineHeight="1.85"
            letterSpacing="0.7px"
            color="marine"
            textAlign="center"
          >
            BOX ID:
          </Heading>
          <Box px={['2px']} textAlign="center">
            <RemoveButton
              fontSize="13px"
              lineHeight="1.85"
              letterSpacing="0.7px"
              type="button"
              color="white"
              textAlign="center"
              onClick={() => removeBox(_id)}
            >
              {t('my_boxit.boxCheckoutModal.payLater')}
            </RemoveButton>
          </Box>
        </Card>
        <Box py="12px" px="10px">
          <Heading
            fontSize={['12px', '12px', '16px']}
            color="blues.peacock"
            pb="20px"
          >
            {_id}
          </Heading>
          <Text fontSize="12px" lineHeight="1.47" color="marine">
            {fullname}
          </Text>
          <Text fontSize="12px" lineHeight="1.47" color="marine">
            {address.street}, {address.city}
          </Text>
          <Text fontSize="12px" lineHeight="1.47" color="marine">
            {address.zipCode} {t('my_boxit.boxCheckoutModal.country')}
          </Text>
        </Box>
        <Box mx="auto"></Box>
        <Box py="12px" pr={['5px', '12px', '12px']} alignSelf="flex-end">
          <Heading
            fontSize="26px"
            lineHeight="1.09"
            textAlign="right"
            color="blues.peacock"
          >
            {currency(shippingCost)}
          </Heading>
          <Text
            pt="12px"
            fontSize="12px"
            lineHeight="2.3"
            letterSpacing="0.6px"
            textAlign="right"
            color="marine"
          >
            at {datetime(createdAt)}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
}

const RemoveButton = styled.button`
  border-radius: 4px;
  border: ${props => (props.border ? props.border : 'solid 1px #01549b')};
  color: #ffffff;
  background-color: ${props => props.theme.colors.blues.peacock};
  text-align: center;
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'bold')};
  font-style: normal;
  font-stretch: normal;
  line-height: ${props =>
    props.lineHeight ? props.lineHeight : props.height ? props.height : '40px'};
  letter-spacing: ${props =>
    props.letterSpacing ? props.letterSpacing : '1px'};
  display: inline-block;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    color: ${props => props.theme.colors.blues.maize};
  }
`;
