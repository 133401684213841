import React, { useState } from 'react';
import { Box, Heading, Text, Button } from 'rebass';
import { gql } from 'apollo-boost';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';

import { Layout } from '../../courier/layout';
import { BoxitModalProvider } from '../../courier/components/Modal';
import BoxPanel from '../../courier/components/BoxPanel';
import CodeConfirmPanel from '../../courier/components/CodeConfirmPanel';
import BoxAdminPanel from '../../courier/components/BoxAdminPanel/BoxAdminPanel';
import BoxCompletedPanel from '../../courier/components/BoxCompletedPanel/BoxCompletedPanel';
import BoxitModal from '../../courier/components/Modal';
import { FooterButton, HomeNavBtn } from '../../courier/components/Buttons'
import ScanningPanel from '../../courier/components/BoxScanningPanel/ScanniingPanel'


const UPDATE_BOX_TRACKING_INFO = gql`
 mutation UpdateTracking( $stationId: String!, $boxIds: [ID!],  $status: String!, $validation: String! ){
  updateBoxTrackingInfo(
    stationId: $stationId
    boxIds: $boxIds
    status: $status
    validation: $validation
  ){
    status
    history{
      status
    }
  } 
}`;



const REQUEST_SF_PIN = gql`
  mutation RequestPin( $phone: String!, $email: String!){
    requestPin(phone: $phone, email:$email)
  }
`;


const VALIDATE_SF_PIN = gql`
  query ValidatePin( $phone: String!, $email:String!, $pin: String!){
    validatePin(phone: $phone, email: $email, pin: $pin)
  } 
`;

const GET_COURIER_BY_PIN = gql`
    query GetCourierByPin( $pin: String!){
      getCourierByPin(pin: $pin, _size:1){
        data{
          name
          _id
        }
      }
    } 
`

const GET_STATION_BOXES = gql`
  query FindBoxesByStatusAndDeliveryPoint($stationId: ID!) {
    findBoxesByStatusAndDeliveryPoint(status:scanned, deliveryPoint: $stationId) {
    data{
      _id
      _ts
      size
      status
      shippingCost
      history {
        _id
        _ts
        status
      }
      recipient {
        fullname
        address {
          street
          zipCode
          city
        }
        email
        phone
      }
      account{
        contact{
          fullname
        }
      }
      deliveryAtHome
      deliveryPoint {
        _id
        name
        lat
        lng
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
      }
      pickupPoint {
        _id
        name
        lat
        lng
        contact {
          fullname
          address {
            street
            zipCode
            city
          }
          phone
          email
        }
      }
    }
    }
  }
`;


export default function PickYourBoxPage({location}) {
  const stationData = location.state && location.state.station ? location.state.station : {};
  const [currentCourier,setCurrentCourier] = useState(null)
  const [step, setStep] = useState(1);
  const [stationState, setStationState] = useState(stationData);
  const [scannedBoxes, setScannedBoxes] = useState([]);
  const [confirmedBoxIds, setConfirmedBoxIds] = useState([]);
  const [confirmedBoxes, setConfirmedBoxes] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [pin, setPin] = useState(null);
  const [disablePinInput, setDisablePinInput] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);



  const [getScannedBoxes] = useLazyQuery(GET_STATION_BOXES, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setDisablePinInput(false)
      if(data.findBoxesByStatusAndDeliveryPoint){
        if(data.findBoxesByStatusAndDeliveryPoint.data.length){
          setScannedBoxes(data.findBoxesByStatusAndDeliveryPoint.data)
        }else{
          openErrorModal('No Boxes Found')
        }
      }else{
        openErrorModal('Error retrieving Boxes ')
      }
    },
    onError: (err) => {
      openErrorModal('Error retrieving Boxes ')
    },
  });

  const [findCourierByPin] = useLazyQuery(GET_COURIER_BY_PIN, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setDisablePinInput(false)
      if(data.getCourierByPin){
        if(data.getCourierByPin.data.length === 1){
          setCurrentCourier(data.getCourierByPin.data)
          getScannedBoxes( {variables: {
            stationId: stationState._id,
          }})
          handleNextStep();
          closeErrorModal();
        }else{
          openErrorModal('Wrong Pin')
        }
      }else{
        openErrorModal('Wrong Pin')
      }
    },
    onError: (err) => {
      openErrorModal('Wrong Pin')
      setDisablePinInput(false)
    },
  });



  const [requestPin] = useMutation(REQUEST_SF_PIN, {
    onCompleted: data => {
      console.log('requesting done', data)
      if(data && data.requestPin){
        handleNextStep();
      }else{
        openErrorModal('Error sending pin')
      }
    },
  });

  const [validatePin] = useLazyQuery(VALIDATE_SF_PIN, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if(data.validatePin){
        handleNextStep();
      }else{
        openErrorModal('Wrong Pin')
      }
      setDisablePinInput(false)
    },
    onError: (err) => {
      openErrorModal('Wrong Pin')
      setDisablePinInput(false)
    },
  });
  

  const handleBoxAdd = boxes => {
    setScannedBoxes(boxes);
  };

  const handleBackStep = () => {
    setStep(step - 1);
    setPin(null);
    setConfirmedBoxIds([]);
    setConfirmedBoxes([])
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const openErrorModal = (msg)=>{
    setErrorMsg(msg);
    setErrorModalOpen(true);
  }

  const closeErrorModal = (msg)=>{
    setDisablePinInput(false)
    setErrorMsg(null);
    setErrorModalOpen(false);
  }

  
  const handleCourierPin = pin =>{
    setDisablePinInput(true)
    openErrorModal('Checking')
    findCourierByPin({
      variables: {
        pin: pin,
      },
    });
  }

  const onSetPin = pin => {
    setDisablePinInput(true)
    if(stationState.pin === pin.toString(10)){
      setDisablePinInput(false)
      setPin(pin.toString(10));
      handleNextStep();
    }else{
      console.log('error', 'pins do not match');
      openErrorModal('pins do not match')
    }
    
  }

  const handleUserPin = pin => {
    setDisablePinInput(true)
    validatePin({ variables: { 
      phone: scannedBoxes[0].recipient.phone, 
      email: scannedBoxes[0].recipient.email, 
      pin } });
    
  }

  const [updateTrackingInfo] = useMutation(UPDATE_BOX_TRACKING_INFO, {
    onError: error => {
      console.error(`Box creation failed! ${JSON.stringify(error)}`);
      openErrorModal('Box creation failed!')
    },
    onCompleted: async data => {
      handleNextStep();
    }
  });

  const updateConfirmedBoxIds = (boxId, isConfirmed)=>{
    console.log(isConfirmed)
      if(isConfirmed){
        const filtered_boxes = scannedBoxes.filter(box=> box._id === boxId)
        setConfirmedBoxIds([...confirmedBoxIds, boxId]);
        setConfirmedBoxes([...confirmedBoxes, ...filtered_boxes]);
       
      }else{
        setConfirmedBoxIds(confirmedBoxIds.filter(id => id !== boxId));
        setConfirmedBoxes(confirmedBoxes.filter(box=> box._id !== boxId));
      }
  }

  const searchBox = (boxId) =>{
    console.log('boxId', boxId)
    const scanned_boxes = scannedBoxes.filter(box=> box._id === boxId)
    if(!confirmedBoxIds.includes(boxId) && scanned_boxes.length === 1){
      updateConfirmedBoxIds(boxId, true)
    }
  }

  const boxTrackingUpdate = () => {
    updateTrackingInfo({
      variables: {
        stationId: stationState._id,
        status: "shipped",
        boxIds: confirmedBoxIds,
        validation: "deliveryPoint"
      },
    });
  }

  const sendSFPin =()=>{
    requestPin({
      variables: {
        phone: scannedBoxes[0].recipient.phone,
        email: scannedBoxes[0].recipient.email,
      },
    });
  }

  const handleBoxScanning = (data)=>{
    if(data.status !== "scanned"){
      return {err:`Καταχώριση δεν επιτρέπεται: ${data.status}`}
    }

    if(data.pickupPoint._id !== stationState._id){
      return {err:`Καταχώριση δεν επιτρέπεται: Wrong Station`}
    }

    const filteredExist = scannedBoxes.filter(box=> box._id === data._id)
    if(filteredExist.length !== 0){
      return {err:`Καταχώριση δεν επιτρέπεται: Added already`}
    }

    const filtered = scannedBoxes.filter(box=> box.recipient.phone !== data.recipient.phone)
    if(filtered.length !== 0){
      return {err:`Καταχώριση δεν επιτρέπεται: Wrong Number`}
    }
    
    const boxes = scannedBoxes.concat([data]);
    handleBoxAdd(boxes);

    return true
    
  }

  const Footer = () => (
    <>
     {step === 1 && (
        <HomeNavBtn text="ΕΞΟΔΟΣ" />
      )}

      {( confirmedBoxIds.length > 0 &&  step === 22 ) && ( 
        <FooterButton onClick={handleNextStep}>ΕΠΟΜΕΝΟ</FooterButton>
      )}

      {step === 5 || step === 4 && (
        <HomeNavBtn text="ΕΞΟΔΟΣ" />
      )}

        {step > 1 && step < 3 && (
          <FooterButton onClick={handleBackStep}>ΕΠΙΣΤΡΟΦΗ</FooterButton>
        )}

        <Box mx="auto" />
      
        {( confirmedBoxIds.length > 0 &&  step === 2 ) && (
        
          <FooterButton onClick={handleNextStep}>ΕΠΟΜΕΝΟ</FooterButton>
          
        )}

{( step === 2 ) && (
        <>
     
                <Button
                    py="25px"
                    px="22px"
                    ml="22px"
                    bg="blues.peacock"
                    borderRadius="4px"
                    onClick={() => setModalOpen(true)}
                  >
                    <Text
                      fontSize="14px"
                      fontWeight="bold"
                      letterSpacing="2.34px"
                    >
                      Scan Boxes
                    </Text>
                  </Button>
        </>
        )}

        {( confirmedBoxIds.length > 0 &&  step === 4 ) && (
            <FooterButton onClick={boxTrackingUpdate}>ΕΠΟΜΕΝΟ</FooterButton>
        )}

        
       

      {step === 5 && (
        <Heading fontSize="40px" lineHeight="0.63" color="blues.peacock">
          weboxit.com
        </Heading>
      )}
    </>
  );

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <CodeConfirmPanel
            title="Courier Login"
            description1="ΠΑΡΑΛΑΒΗ ΔΕΜΑΤΩΝ"
            buttonText="ΕΙΣΟΔΟΣ COURIER WEBOXIT"
            onSetPin={handleCourierPin}
            disablePinInput={disablePinInput}
        />
       );
      case 2:
        return (
          <BoxAdminPanel 
            scannedBoxes={scannedBoxes}
            confirmedBoxIds={confirmedBoxIds}
            updateConfirmedBoxIds={updateConfirmedBoxIds}
            useScanner
          />
        );
      case 3:
        return (
          <CodeConfirmPanel
            title="Μπορείτε να παραδώσετε τα δέματα σας!"
            description1="Παρακαλούμε, απευθυνθείτε στον υπάλληλο του πρακτορείου AEGEAN OIL που διαχειρίζεται τον συγκεκριμένο σταθμό, για να παραλάβει τα δέματα σας προς αποστολή."
            buttonText="ΕΙΣΟΔΟΣ ΔΙΑΧΕΙΡΙΣΤΗ ΣΤΑΘΜΟΥ WEBOXIT"
            onSetPin={onSetPin}
          />
        );
      case 4:
        return (
          <BoxPanel
            addButtonText="ΠΑΡΑΛΑΒΗ ΔΕΜΑΤΟΣ"
            helpText="Για να προσθέσετε νέο Δέμα επιλέξτε 'ΠΡΟΣΘΗΚΗ ΔΕΜΑΤΟΣ' και
            χρησιμοποιήστε το scanner που διαθέτει ο Σταθμός."
            scannedBoxes={confirmedBoxes}
            totalBoxes={scannedBoxes}
            handleBoxScanning={handleBoxScanning}
          />
        );
      case 5:
        return (
          <BoxCompletedPanel
            infoText="Μπορείτε τώρα να παραλάβετε τα δέματα σας, 
        από τον διαχειριστή αυτού του Σταθμού."
          />
        );
    }
  };

  return (
    <Layout footer={() => <Footer />}>
      <BoxitModalProvider>
        <BoxitModal
              isOpen={errorModalOpen}
              onBackgroundClick={() => closeErrorModal()}
              onEscapeKeydown={() => closeErrorModal()}
            >
              <Text>
                {errorMsg}
              </Text>
        </BoxitModal>
        <BoxitModal
            isOpen={modalOpen}
            onBackgroundClick={() => setModalOpen(false)}
            onEscapeKeydown={() => setModalOpen(false)}
          >
            <ScanningPanel 
                loadingIndicator={loadingIndicator} 
                searchBox={searchBox} 
                alertMessage={alertMessage}
              />
          </BoxitModal>
        {renderStep()}
      </BoxitModalProvider>
    </Layout>
  );
}
